exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-articles-index-js": () => import("./../../../src/templates/articles/index.js" /* webpackChunkName: "component---src-templates-articles-index-js" */),
  "component---src-templates-articles-list-page-index-js": () => import("./../../../src/templates/articlesListPage/index.js" /* webpackChunkName: "component---src-templates-articles-list-page-index-js" */),
  "component---src-templates-pages-index-js": () => import("./../../../src/templates/pages/index.js" /* webpackChunkName: "component---src-templates-pages-index-js" */),
  "component---src-templates-questionnaires-index-js": () => import("./../../../src/templates/questionnaires/index.js" /* webpackChunkName: "component---src-templates-questionnaires-index-js" */),
  "component---src-templates-resources-index-js": () => import("./../../../src/templates/resources/index.js" /* webpackChunkName: "component---src-templates-resources-index-js" */),
  "component---src-templates-resources-list-page-index-js": () => import("./../../../src/templates/resourcesListPage/index.js" /* webpackChunkName: "component---src-templates-resources-list-page-index-js" */),
  "component---src-templates-values-index-js": () => import("./../../../src/templates/values/index.js" /* webpackChunkName: "component---src-templates-values-index-js" */),
  "component---src-templates-values-print-js": () => import("./../../../src/templates/values/print.js" /* webpackChunkName: "component---src-templates-values-print-js" */)
}

